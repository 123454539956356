import {createLogger, createStore} from 'vuex'
import app from "./modules/app"
import assessment from "./modules/assessment"
import codebooks from "./modules/codebooks"
import courses from "./modules/courses"
import page from "./modules/page"
import user from "./modules/user"
import video from "./modules/video"
import studyPrograms from "./modules/studyPrograms"

export default createStore({
    plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
    modules: {
        app,
        courses,
        page,
        user,
        codebooks,
        video,
        assessment,
        studyPrograms,
    }
})
